@import "src/assets/scss/mixins";

.schedule_item_container {
  .item_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f2f9;
    padding: $sp-3xm $sp-l;

    .col_1 {
      display: grid;
      grid-template-columns: auto auto auto;
      align-items: center;
      gap: $sp-3xm;

      .date_range_container {
        font-weight: 500;
        display: grid;
        grid-template-columns: auto auto auto auto;
        gap: $sp-xs;
      }

      .icon_container {
        color: #597EF7;
        font-size: $sp-l
      }
    }

    .status_container {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      gap: $sp-xs;

      .is_active {
        width: $sp-3xm;
        height: $sp-3xm;
        display: inline-block;
        border-radius: $sp-10xl;

        &.draft {
          background-color: #b4c9ea;
        }

        &.planned {
          background-color: #f2ca79;
        }

        &.in_progress {
          background-color: #f1f257;
        }

        &.completed {
          background-color: #57c07d;
        }

        &.cancelled {
          background-color: #d70606;
        }

        &.terminated {
          background-color: #ee6b75;
        }
      }
    }

    .col_2 {
      display: flex;
      align-items: center;
      gap: $sp-3xm;
    }
  }


  .item_body {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));


    @media (max-width: 1300px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }


    .price_item {
      display: grid;
      grid-template-columns: auto auto;
      gap: 5px;

      .price_code {
        font-weight: 600;
      }
    }

    .column_item {
      position: relative;
      padding: $sp-2xm $sp-xm;

      .column_item_content {
        margin-top: $sp-3xm
      }

      @media (max-width: 1300px) {
        border: 1px solid rgba(212, 207, 207, 0.74);
        margin: 2px;
        &::after {
          display: none;
        }
      }

      &:not(:first-of-type) {
        &::after {
          content: '';
          left: 1px;
          width: 1px;
          height: 80%;
          top: $sp-3xm;
          position: absolute;
          background-color: #dbdada;
        }
      }

      .schedule_table_button {
        cursor: pointer;
        padding: 4px 8px;
        border-radius: 10px;
        color: #fff;
        border: none;
        background-color: #3e94ec;
        transition-duration: 0.5s;

        &.disabled_button {
          opacity: 0.5;
          pointer-events: none;
        }

        &:hover {
          background-color: #40a9ff;
        }
      }

      .inline_content {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 10px;
      }

      .column_text_content {
        white-space: pre-wrap;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }

      .column_day_content {
        display: inline-block;
        margin: 6px;

        span {
          padding: 5px 8px;
          color: #597EF7;
          border-radius: 10px;
          background-color: rgba(89, 126, 247, 0.13);
        }
      }

      .column_time_content {
        span {
          color: black;
          padding: 6px 12px;
          background-color: #eae9e9;
          border-radius: 15px;
        }
      }

      .schedule_type_content {
        display: inline-block;
        border-radius: 4px;
        padding: 3px 8px;

        &.one_time {
          @include statusStyle(#41d2d2, #e1f6f6, #74e2e2);
        }

        &.recurring {
          @include statusStyle(#53a578, #3b935f0d, #7cba95);
        }
      }

    }
  }
}