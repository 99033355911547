.language_drop_down_content {
  .ant-btn {
    background-color: transparent;
    border: none;
    height: 35px;
    box-shadow: none;
  }

  &.active_border {
    .ant-btn {
      border: 1px solid #ccc;
      border-radius: 10px;
    }
  }
}