@import "src/assets/scss/mixins";

.linkWrapper {
  color: $link-color;
  
  &.medium {
    @include fontWeight(medium);
  }

  &.regular {
    @include fontWeight(regular);
  }

  &.bold {
    @include fontWeight(bold);
  }

  &.small {
    @include link(small)
  }

  &.base {
    @include link(base)
  }
}
