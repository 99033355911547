.avatar_drop_down_content {
  width: 300px;
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;

  .user_profile_info {
    display: flex;
    text-align: center;
    padding: 20px 0;
    justify-content: center;
    border-bottom: 1px solid rgba(204, 204, 204, 0.42);

    h3 {
      color: #374151;
      margin: 5px 0;
    }

    span {
      color: rgba(55, 65, 81, 0.55);
    }
  }

  .menu_list {
    overflow: hidden;
  }
}