.user-profile-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  .auth_avatar {
    background-color: red;
  }
  .user-profile {
    background-color: rgba(237, 237, 246, 0.68);
    display: flex;
    align-items: center;
    padding: 5px 10px;
    .avatar {
      margin-left: 10px;
    }
  }
}