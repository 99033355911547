.lp_btn_dropdown {
  position: relative;
  top: 50px;
  left: 45%;
  margin-top: 15px;
  //width: 300px;
  //height: 50px;

  &::before {
    content: "";
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 1000;
    width: 8px;
    height: 8px;
    border: 2px solid #333;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg);
    transition: 0.5s;
    pointer-events: none;
  }

  &.active::before {
    top: 22px;
    transform: rotate(-225deg);
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: #fff;
    font-size: 1.2em;
    border: none;
    outline: none;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    padding: 12px 20px;
    border-radius: 10px;
  }
  .options {
    position: absolute;
    top: 70px;
    width: 100%;
    background: #fff;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    overflow: hidden;
    display: none;
  }
  &.active .options {
    display: block;
    div {
      padding: 12px 20px;
      cursor: pointer;
      &:hover {
        background: #62baea;
        color: #fff;
      }
    }
  }
}