.web_navigation_skeleton_container {
  margin: 0 10px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  .skeleton_input_item {
    //width: 80px;
    height: 30px;
    >span {
      height: 30px;
      width: 100%;
    }
  }
}