@import 'src/assets/scss/variables';

.landing_page_header_container {
  width: 100%;
  z-index: 999;
  position: fixed;
  transition: top 0.3s;
  height: $header-height;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .landing_page_header_main_content {
    margin: auto;
    display: grid;
    height: 100%;
    padding: 10px;
    align-items: center;
    background-color: #fff;
    grid-template-columns: auto 1fr;
    max-width: $landing-page-container-max-width;
    grid-template-areas: 'logo navigation navigation';

    @media (max-width: 1024px) {
      //background-color: red;
    }

    .logo_container {
      grid-area: logo;
    }

    .navigation_right_container {
      grid-area: navigation;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-areas: 'navigation_c navigation_c right_content';
      .navigation {
        grid-area: navigation_c;
      }
      .right_content {
        display: flex;
        align-items: center;
        grid-area: right_content;
      }
    }

  }
}