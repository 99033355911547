.date_picker_container {
  border-radius: 5px !important;
  padding: 5px 9px;
  &.readonly_date_picker {
    pointer-events: none;
    border-color: transparent;
    .ant-picker-suffix {
      display: none;
    }
  }
}