.ant_des_modal {
  .ant-modal-header {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
}

.ant_des_modal_cancel_button {
  border: 1px solid #597EF7;
  border-radius: 5px !important;
  color: #597EF7;
}

.ant_des_modal_save_button {
  background-color: #597EF7;
  border-radius: 5px !important;
}
