.ant_des_select {
  .ant-select-selector {
    border-radius: 5px !important;
  }
}


.tags_select {
  .ant-select-selector {
    padding: 3px 4px !important;
  }
}
.readonly_select {
  pointer-events: none;
  .ant-select-selector {
    border-color: transparent !important;
  }
  .ant-select-arrow {
    display: none;
  }
}