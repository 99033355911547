@import "src/assets/scss/variables";

.landing_page_feedbacks_container {
  margin-top: $sp-4xl;

  .feedbacks_item_content {
    display: grid;
    gap: $sp-l;

    .feedback_item {
      padding: $sp-2xm;
      width: 100%;
      display: block;
      max-height: 500px;
      border-radius: $sp-2xm;
      border: 1px solid rgba(204, 204, 204, 0.56);

      .feedbacks_text {
        overflow-wrap: anywhere;
      }

      .info_data {
        gap: $sp-3xm;
        display: grid;
        grid-template-columns: 60px auto;
        margin-bottom: $sp-3xm;
        .author_name {
          overflow-wrap: anywhere;
          //width: 200px;
        }
      }

      .user_info {
        display: flex;
        gap: $sp-3xm;
        align-items: center;

        .ant-typography {
          margin: 0;
        }
      }
    }
  }

  .see_more_container {
    text-align: center;
    margin: $sp-l 0;
  }
}

