.upload-img-content {
  display: flex;
  padding: 0 0 10px 0;

  .ant-upload-picture-card-wrapper {
    width: auto;
  }

  .text-content {
    width: 160px;
  }
}