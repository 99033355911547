.button_inside_input_content {
  display: flex;
  align-items: center;

  input {
    position: relative;
    display: inline-block;
    font-size: 18px;
    box-sizing: border-box;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0 20px;
    height: 50px;
    width: 100%;
    border-radius: 13px 0 0 13px;
    &:focus {
      border: none !important;
      box-shadow: none;
    }
  }
  &.ant-form-item-has-error:focus {
    border: none ;
    box-shadow: none;
  }

  button {
    padding: 0 15px;
    border-radius: 0 13px 13px 0;
    margin-top: 0;
    border: none;
    outline: none;
    cursor: pointer;
    height: 50px;
    background-color: #2728E5;
    transition-duration: 0.5s;
    &:hover {
      background-color: #6464f8;
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}