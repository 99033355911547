.main_collapse_container {
  .ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    padding: 0;
  }

  .ant-collapse-header-text {
    width: 100%;
  }

  .panel_header_content {
    padding: 15px;

  }

  .full_width {
    width: 100%;
  }

  //.space_between {
  //  display: flex;
  //  align-items: center;
  //  width: 100%;
  //  justify-content: space-between;
  //}

  &.active {
    .ant-collapse-item {
      outline: 1px solid #597EF7;
    }
  }
}