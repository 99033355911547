.text_input {
  border-radius: 5px !important;
  padding: 4px 10px !important;
  &.readonly_text_input {
    border-color: transparent;
    pointer-events: none;
  }

  &.keep_read_only_style {
    border: 1px solid #d9d9d9;
  }
}