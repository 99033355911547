@import "src/assets/scss/variables";

.textEllipsisBlock {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.textEllipsisInline {
  display: inline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


//profile setup classes
.skills-content, .certifications-content, .translations-content {
  margin: $sp-2xl 0;

  .required_style {
    position: relative;
    &:before {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
      position: absolute;
      top: -5px;
    }
  }
  .title-content {
    display: flex;
    align-items: center;
    gap: $sp-s;

  }
}


.ant-popover-inner {
  border-radius: 10px !important;
}

.ant-dropdown-menu {
  border-radius: 10px !important;
}

.pointerCursor {
  cursor: pointer;
}