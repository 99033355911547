@import "src/assets/scss/variables";

.is-auth-header {
  &.ant-layout-header {
    background: #fafbfe;
    padding: 0 $sp-l;
  }

  .header-main-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .chat_bell_outlined {
    cursor: pointer;
  }
}