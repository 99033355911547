@import "src/assets/scss/variables";

.button-wrapper {
  border-radius: $button-primary-radius !important;

  &:disabled {
    pointer-events: none;
    color: #fff;
    opacity: 0.5;
  }

  //size custom config
  &.medium {
    height: 37px !important;
    min-width: 90px !important;
  }

  &.small {
    height: 32px !important;
    min-width: 55px !important;
  }

  &.ant-btn-dangerous {
    color: red !important;
    border: 1px solid red !important;
    &:hover {
      background-color: red !important;
      color: #ffff !important;
    }
  }

  &.ant-btn-default {
    color: #597EF7FF;
    border: 1px solid $button-primary-color;

    &:hover {
      color: #ffff;
      background-color: $button-primary-color;;
    }
  }

  &.ant-btn-primary {
    background-color: $button-primary-color;
    border: none;

    &:focus {
      background-color: #40a9ff;
    }

    &:hover {
      background-color: #40a9ff;

      &:focus {
        background-color: #40a9ff;
      }
    }

  }
}



