@import "src/assets/scss/mixins";

.bodyTextWrapper {

  &.medium {
    @include fontWeight(medium)
  }

  &.regular {
    @include fontWeight(regular)
  }

  &.bold {
    @include fontWeight(bold)
  }

  &.extraSmall {
    @include bodyTextTypes(extraSmall)
  }

  &.small {
    font-size: 22px;
    @include bodyTextTypes(small)
  }

  &.base {
    @include bodyTextTypes(base)
  }

  &.large {
    @include bodyTextTypes(large)
  }

  &.extraLarge {
    @include bodyTextTypes(extraLarge)
  }
}