@import "src/assets/scss/variables";

.content_management_section_skeleton {
  .top_skeleton {
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .skeleton_input_container {
      display: grid;
      gap: 20px;

      .skeleton_input {
        width: 100%;
        height: 200px;
        border-radius: 10px;
      }
    }
  }


  .footer_skeleton {
    display: grid;
    gap: 20px;
    margin-top: 10px;
    grid-template-columns: 1fr 1fr;
    .right_content {
      display: grid;
      gap: 20px;
      margin-top: 10px;
      grid-template-columns: 1fr 1fr;
    }
    .skeleton_input {
      width: 100%;
      height: 200px;
      border-radius: 10px;
    }
  }
}