@import "src/assets/scss/variables";
@import "src/assets/scss/_mixins";

body {
  background-color: #f6f6f6;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.collapsed-action-container {
  padding: 15px 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  color: #fff;
}


.input-content {
  border-radius: $input-radius;
}

.button-container {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.ant-select-selection {
  border-radius: 20px;
  background-color: red;
}

.header-content-layout {
  padding: 20px 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.table-title-action {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.status-name-content {
  display: inline-block;
  border-radius: 4px;
  padding: 3px 8px;

  //revenue statuses
  &.PENDING {
    @include statusStyle(#2F54EB, #F0F5FF, #ADC6FF);
  }

  &.CANCELED {
    @include statusStyle(#F5222D, #FFF1F0, #FFA39E)
  }

  &.PAYED {
    @include statusStyle(#52C41A, #F6FFED, #B7EB8F);
  }
  //----------------------------------------------

  &.draft {
    @include statusStyle(#ed3833, rgba(237, 56, 51, 0.07), #ed3833)
  }

  &.updated {
    @include statusStyle(#4768ed, rgba(71, 104, 237, 0.07), #4768ed);
  }

  &.published {
    @include statusStyle(#5cc431, rgba(92, 196, 49, 0.07), #5cc431);
  }

  &.NEW {
    @include statusStyle(#facf70, #FFFBE6, #FFE58F);
  }

  &.PENDING_ACTIVATION {
    @include statusStyle(#2F54EB, #F0F5FF, #ADC6FF);
  }

  &.PENDING_PROFILE {
    @include statusStyle(#722ED1, #F9F0FF, #D3ADF7);
  }

  &.PUBLISH_ACTIVE, &.ACTIVE {
    @include statusStyle(#52C41A, #F6FFED, #B7EB8F);
  }


  &.PENDING_REVIEW {
    @include statusStyle(#41d2d2, #e1f6f6, #74e2e2);
  }

  &.BLOCK {
    @include statusStyle(#F5222D, #FFF1F0, #FFA39E)
  }

  &.DRAFT {
    @include statusStyle(#F5222D, #FFF1F0, #FFA39E)
  }

  &.SUSPENDED {
    @include statusStyle(#2F54EB, #F0F5FF, #ADC6FF)
  }

  &.MENTORING {
    @include statusStyle(#722ED1, #f6f6f6, #b583fa);
  }

  &.COURSE {
    @include statusStyle(#2F54EB, #F0F5FF, #ADC6FF)
  }
}

.schedule_types {
  &.round {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100px;
  }

  &.button_style {
    display: inline-block;
    border-radius: 4px;
    padding: 3px 8px;
    color: #ffffff;
  }

  &.DRAFT {
    background-color: #b4c9ea;
  }

  &.PLANNED {
    background-color: #f2ca79;
  }

  &.IN_PROGRESS {
    background-color: #f1f257;
  }

  &.COMPLETED {
    background-color: #57c07d;
  }

  &.CANCELLED {
    background-color: #d70606;
  }

  &.TERMINATED {
    background-color: #ee6b75;
  }
}

.site-layout {
  width: 100%;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;

  .main-content {
    padding: 20px;
  }
}

.footer-content-layout {
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 15px;
  margin-top: 20px;
  display: grid;
  align-items: center;
  justify-content: end;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

//courses style
.courses-implement-content {
  padding: 10px 5px;
  max-width: 550px;
}

.content-layout {
  margin: auto;
  padding: 25px;
  max-width: 1300px;
}

//--------------------- flex style ---------------------

.flex_start {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.flex_end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.full_width {
  width: 100%;
}

//------------------------

//--------------------- Landing page ---------------------

.landing_page_container {
  margin-top: $distance-to-header;
}


.landing_page_max_width_content {
  margin: auto;
  padding: 0 $sp-l;
  max-width: $landing-page-container-max-width;
}

//------------------------

.column_2_layout {
  display: grid;
  gap: 100px;
  grid-template-columns: 1fr 50%;
  justify-content: space-between;
}

.absolute_bg_img {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
}

.header_top_margin {
  margin-top: $header-height + 10px;
}

.activeCategoryLink {
  color: $link-color  !important;
}

.textEllipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-layout-max-width {
  max-width: 580px;
}

.align_center {
  display: flex;
  align-items: center;
}

.space_between {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.container_width {
  max-width: 1300px;
  padding: 10px;
  margin: auto;
}
