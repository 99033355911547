@import '../../../../assets/scss/variables';

.success-message-content {
  max-width: 560px;
  border: 1px solid rgba(221, 220, 220, 0.92);
  margin: 100px auto;
  border-radius: $container-radius;
  text-align: center;
  background-color: #fff;
  padding: 25px 40px;
  .link-content {
    text-decoration: underline;
    margin-top: 15px;
    a {
      color: #5754DB;
    }
  }
  .icon-content {
    margin-top: 20px;
  }
}