$primary: #2728E5;
$warning: #FEDD1A;
$white: #fdfdfd;


.lp_btn {
  cursor: pointer;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  transition-duration: 0.4s;
  border: none;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.loading {
    opacity: 0.6;
    pointer-events: none;
  }

  &.fullWidth {
    width: 100%;
  }

  &.warning {
    background-color: $warning;
    font-weight: bold;
  }

  &.primary {
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: #494ae2;
    }
  }

  //Button Size
  &.medium {
    padding: 13px 26px;
    font-size: 16px;
    font-weight: 100;
  }

  &.small {
    padding: 6px 16px;
    font-size: 14px;
    text-align: center;
  }

  &.full_radius {
    border-radius: 40px;
  }

  &.corner_radius {
    border-radius: 10px;
  }
}