.ant_des_search_container {
  .ant_des_search {
    input {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  .ant-btn-icon-only {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}


