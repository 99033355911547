.web_menu_navigation {
  .ant-menu-horizontal {
    border-bottom: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* width */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.navigationLinkItem {
  max-width: 100px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
