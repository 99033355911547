@import "src/assets/scss/variables";

.footer_container {
  background-color: #F3F4F6;

  .footer_main_container {
    padding: $sp-l;

    .divider {
      width: 100%;
      height: 1px;
      background-color: #cccc;
    }

    .footer_text {
      display: block;
      margin: $sp-3xm 0;
    }
  }


  .news_letter {
    display: grid;
    gap: $sp-5xl;
    grid-template-columns: 1fr 1fr;

    .col_left {
      grid-column: span 1;

      .socials_list {
        display: flex;
        gap: 10px;

        a {
          font-size: $sp-l;
          color: #888b91;
        }
      }
    }

    .col_left, .col_right {
    }
  }

  .navigation_container {
    display: grid;
    margin-top: $sp-l;
    align-items: center;
    grid-template-columns: 1fr auto;

    .web_menu_navigation .ant-menu-horizontal {
      justify-content: flex-start;
    }

    .ant-menu {
      li:first-of-type {
        padding-left: 0;
      }
    }
  }

  .footer_copyright_content {
    width: 100%;
    display: flex;
    padding: $sp-l 0;
    justify-content: center;
    background-color: rgba(195, 211, 246, 0.24);
  }
}
