.main_side_bar {
  height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }

  .ant-menu-sub {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .collapsed_action_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 10px 15px;
  }
}

.main_side_bar {
  overflow: hidden;
  height: 100vh;
}

.ant-menu-dark.ant-menu-inline, .ant-menu-root {
  overflow: scroll;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
}